<template>
	<div style="display: flex;flex-wrap: wrap;justify-content:space-between;">
		<div  style="display: flex;flex-direction: row;align-items: center;">
			<Button label="Apply New Key" icon="pi pi-plus" class="p-button-success" @click="ApplyKey" />
		</div>
		<div  style="display: flex;flex-direction: row;align-items: center;">
		<!-- 	<div class="p-inputgroup" title="click to dropdown and select">
				<Button label="" icon="pi pi-search" :class="'p-button-'+this_operate.button_class" @click="initialSearch" />
				<Dropdown v-model="this_operate" :options="operate_list" optionLabel="name" placeholder="select search type" :showIcon="true" style="min-width:4rem" />
			</div> -->
			<div style="margin-right: 5px;">
				<div class="p-inputgroup" style="width:150px;" title="input page and go">
					<InputText id="page_num_input" style="font-size: 14px;" v-on:keyup.enter="startSearch" @input="pageNumInput" @blur='checkInputNum' :value="current_page_num" type="number" placeholder="跳转页码" />
					<Tag class="p-mr-2" style="font-size: 14px;" severity="info" :value="'/'+total_page_num+' Page'"></Tag>
				</div>
			</div>
			<Button title="Before" label="" icon="pi pi-arrow-left" :disabled="current_page_num==1 || current_page_num>total_page_num" class="p-button-info"
				@click="getNeighborPage(-1)" style="margin-right:.25em" />
			<Button title="Next" label="" icon="pi pi-arrow-right" :disabled="current_page_num>=total_page_num" class="p-button-info"
				@click="getNeighborPage(1)" style="margin-right:.25em" />
			<Button type="button" icon="pi pi-filter-slash" title='clear filter'
				class="p-button-outlined" @click="initFilters1()" />
		</div>
	</div>
</template>

<script>
	import NetWorkService from '../service/NetWorkService.js';
	export default {
		name: '',
		props: {
			operate_list_copy:{
				default: null,
				type: Array
			},
			this_operate_copy:{
				default: null,
				type: Object
			}
		},
		components: {
			
		},
		data() {
			return{
				operate_list:this.operate_list_copy, //此后para可在methods中操作
				this_operate:this.this_operate_copy,
				current_page_num:1,
				total_page_num:0,
			}
		},
		networkService:null,
		created() {
			this.networkService = new NetWorkService();
		},
		mounted() {
			// console.log('组件加载成功');
		},
		methods: {
			//检查输入是否出错
			checkInputNum(){
				var page_num_input = document.getElementById("page_num_input");
				if(page_num_input.value==''){
					this.current_page_num=1;
					document.getElementById("page_num_input").value=1;
					return;
				}
			},
			//初始化搜索
			initialSearch(){
				this.current_page_num=1;
				this.total_page_num=1;
				this.$emit('initPage');
				this.$emit('initFilters1');
				this.startSearch();
			},
			//页码框输入
			pageNumInput(){
				var page_num_input = document.getElementById("page_num_input");
				var keyword = page_num_input.value;
				if(keyword==''){
					this.current_page_num = '';
					console.log('输入为空');
				}else{
					this.current_page_num = parseInt(keyword);
				}
			},
			//翻页
			getNeighborPage(delta) {
				this.current_page_num = this.current_page_num + delta;
				this.startSearch();
			},
			//构建查询数据
			getStructSearchData(){
				var search_data={
					'topic':'all',
					'page_num':this.current_page_num,
					'status':this.this_operate.status,
				}
				return search_data;
			},
			//构建查询数据
			getStructWordsSearchData() {
				var search_data = {
					'status': this.this_status.status,
					'page_num': this.current_page_num,
					'user_email': this.$appState.user_email,
				}
				return search_data;
			},
			startSearch(){
				if (this.current_page_num<1 || this.current_page_num>this.total_page_num || this.current_page_num==''){
					this.current_page_num=1;
					document.getElementById("page_num_input").value=1;
					return;
				}
				this.$emit('startSearch');
			},
			initFilters1(){
				this.$emit('initFilters1');
			}
		},
		}
</script>

<style scoped lang="scss">
</style>
