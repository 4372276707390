import axios from 'axios';
var base_url = "/api";
// var base_url="http://127.0.0.1:8299/api";
// var base_url="http://124.221.121.97:8299/api";
// ajax请求统一增加请求头
axios.interceptors.request.use(config => {
    config.timeout = 600000;
    return config
  })

export default class NetWorkService {
	//post方法
	postService(url, data) 
	{
		//每次请求的时候，设置处理为1，处理完成后设置为0
		// this.getIP()
		var posturl = base_url + url;
		var this_header={
			'Authorization':localStorage.getItem('Authorization')==undefined?'':localStorage.getItem('Authorization'),
		}
		
		
		return axios.post(posturl,data,{'headers':this_header}).then(res => {
			if (res.data.code==429){
				alert('服务器繁忙，请稍后再试！')
				return {}
			}
			return res.data;
		}).catch(function (err) {
			// alert('操作失败，请稍后再试！');
			var current_second=new Date().getTime();
			Date.prototype.toLocaleString = function() {
					return this.getFullYear() + "-" + (this.getMonth() + 1) + "-" + this.getDate() +'  ' + this.getHours() + ":" + this.getMinutes() + ":" + this.getSeconds();
				};
			// 按重写的自定义格式，格式化日期
			var dateTime = new Date(current_second).toLocaleString();
			alert("["+dateTime+"]\r\n"+posturl+"错误："+err+"\r\n如需帮助请截屏此错误发送邮件到 libertynlp@163.com");
			var resp={
				'code':-1,
				'data':{},
			}
			return resp;
		});
	}
}
