<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<DataTable :value="authkeys" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id"
					:rowHover="true" v-model:filters="filters1" filterDisplay="menu" :loading="loading1"
					:filters="filters1" responsiveLayout="scroll"
					:globalFilterFields="['name','country.name','representative.name','balance','status']">

					<template #header>
						<pageOperator ref='pageOperate' :operate_list_copy='words_list_status'
							:this_operate_copy='this_status' @startSearch='getPersonalPaper' @initPage='initPage'
							@initFilters1='initFilters1' />
					</template>
					<template #empty>
						No customers found.
					</template>
					<template #loading>
						Loading customers data. Please wait.
						<div style="margin-top: 0.625rem;">Note: default load 200 records, need more please click Next button</div>
					</template>
					<Column field="key" header="Key" style="min-width:14rem">
						<template #body="{data}">
							{{data.key}}
						</template>
						<template #filter="{filterModel}">
							<InputText type="text" v-model="filterModel.value" class="p-column-filter"
								placeholder="Search by name" />
						</template>
					</Column>
					<Column field="domain" header="domain" style="min-width:14rem">
						<template #body="{data}">
							{{data.domain}}
						</template>
						<template #filter="{filterModel}">
							<InputText type="text" v-model="filterModel.value" class="p-column-filter"
								placeholder="Search by name" />
						</template>
					</Column>
					<Column header="price" filterField="price" dataType="numeric" style="min-width:7rem">
						<template #body="{data}">
							{{formatCurrency(data.price)}}
						</template>
						<template #filter="{filterModel}">
							<InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
						</template>
					</Column>
					<Column header="created_date" filterField="created_date" dataType="date" style="min-width:7rem">
						<template #body="{data}">
							{{formatDate(data.created_date)}}
						</template>
						<template #filter="{filterModel}">
							<Calendar v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />
						</template>
					</Column>
					<Column header="valid_date" filterField="valid_date" dataType="date" style="min-width:7rem">
						<template #body="{data}">
							{{formatDate(data.valid_date)}}
						</template>
						<template #filter="{filterModel}">
							<Calendar v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />
						</template>
					</Column>
					<Column field="status" header="Status" :sortable="true">
						<template #body="slotProps">
							<span
								:class="'order-badge order-' + (slotProps.data.status ? slotProps.data.status.toLowerCase() : '')">{{slotProps.data.status}}</span>
						</template>
					</Column>
					<Column header="operate" :filterMenuStyle="{'width':'10rem'}" style="min-width:5rem">
						<template #body="slotProps">
							<Button title='copy key' icon="pi pi-book" style="margin-left: 0.3125rem;"
								class="p-button-rounded p-button-info p-mr-2" @click="editProduct(slotProps.data)" />
							<Button title='change domain and create new key' icon="pi pi-pencil"
								class="p-button-rounded p-button-success p-mr-2" @click="editProduct(slotProps.data)" />
						</template>
					</Column>
				</DataTable>
			</div>
		</div>
	</div>
</template>

<script>
	import ProductService from '../service/ProductService';
	import pageOperator from './pageOperator.vue';
	import {
		FilterMatchMode,
		FilterOperator
	} from 'primevue/api';

	export default {
		data() {
			return {
				filters1: null,
				filters2: {},
				loading1: true,
				idFrozen: false,
				authkeys: null,
				productDialog: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				product: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				words_list_status: [{
						'name': 'valid',
						'button_class': 'info',
						'status': 'all',
						'id': '1'
					},
					{
						'name': 'expired',
						'button_class': 'danger',
						'status': 'untag',
						'id': '2'
					},
					{
						'name': 'verifying',
						'button_class': 'success',
						'status': 'taged',
						'id': '3'
					}
				],
				this_status: {
					'name': '所有文章',
					'status': 'all',
					'button_class': 'info',
					'id': '1'
				},

			}
		},
		components: {
			'pageOperator': pageOperator,
		},
		productService: null,
		created() {
			this.productService = new ProductService();
			this.initFilters();
		},
		mounted() {
			// this.productService.getProducts().then(data => this.products = data);
			this.processDate();
		},
		methods: {
			processDate() {
				this.authkeys = [{
					"id": "1000",
					"key": "f230fh0g3",
					"domain": "demos.libertynlp.com",
					"price": 65,
					"level": "1",
					"created_date": "2015-09-13",
					"valid_date": "2015-09-13",
					"status": "valid",
				}, {
					"id": "1000",
					"key": "dfajlkdsfgbn1",
					"domain": "dict.libertynlp.com",
					"price": 95,
					"level": "2",
					"created_date": "2015-09-13",
					"valid_date": "2015-09-13",
					"status": "expired",
				}, {
					"id": "1000",
					"key": "dfahjqefnqj",
					"domain": "firefly.libertynlp.com",
					"price": 195,
					"level": "3",
					"created_date": "2015-09-13",
					"valid_date": "2015-09-13",
					"status": "verifing",
				}];
				this.authkeys.forEach(authkey => authkey.created_date = new Date(authkey.created_date));
				this.authkeys.forEach(authkey => authkey.valid_date = new Date(authkey.valid_date));
				this.loading1 = false;
			},
			openNew() {
				this.product = {};
				this.submitted = false;
				this.productDialog = true;
			},
			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			saveProduct() {
				this.submitted = true;
				if (this.product.name.trim()) {
					if (this.product.id) {
						this.product.inventoryStatus = this.product.inventoryStatus.value ? this.product.inventoryStatus
							.value : this.product.inventoryStatus;
						this.products[this.findIndexById(this.product.id)] = this.product;
						this.$toast.add({
							severity: 'success',
							summary: 'Successful',
							detail: 'Product Updated',
							life: 3000
						});
					} else {
						this.product.id = this.createId();
						this.product.code = this.createId();
						this.product.image = 'product-placeholder.svg';
						this.product.inventoryStatus = this.product.inventoryStatus ? this.product.inventoryStatus.value :
							'INSTOCK';
						this.products.push(this.product);
						this.$toast.add({
							severity: 'success',
							summary: 'Successful',
							detail: 'Product Created',
							life: 3000
						});
					}
					this.productDialog = false;
					this.product = {};
				}
			},
			editProduct(product) {
				this.product = {
					...product
				};
				this.productDialog = true;
			},
			confirmDeleteProduct(product) {
				this.product = product;
				this.deleteProductDialog = true;
			},
			deleteProduct() {
				this.products = this.products.filter(val => val.id !== this.product.id);
				this.deleteProductDialog = false;
				this.product = {};
				this.$toast.add({
					severity: 'success',
					summary: 'Successful',
					detail: 'Product Deleted',
					life: 3000
				});
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}
				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for (var i = 0; i < 5; i++) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
			confirmDeleteSelected() {
				this.deleteProductsDialog = true;
			},
			deleteSelectedProducts() {
				this.products = this.products.filter(val => !this.selectedProducts.includes(val));
				this.deleteProductsDialog = false;
				this.selectedProducts = null;
				this.$toast.add({
					severity: 'success',
					summary: 'Successful',
					detail: 'Products Deleted',
					life: 3000
				});
			},
			initFilters() {
				this.filters1 = {
					'global': {
						value: null,
						matchMode: FilterMatchMode.CONTAINS
					},
					'key': {
						operator: FilterOperator.AND,
						constraints: [{
							value: null,
							matchMode: FilterMatchMode.STARTS_WITH
						}]
					},
					'domain': {
						operator: FilterOperator.AND,
						constraints: [{
							value: null,
							matchMode: FilterMatchMode.STARTS_WITH
						}]
					},
					'country.name': {
						operator: FilterOperator.AND,
						constraints: [{
							value: null,
							matchMode: FilterMatchMode.STARTS_WITH
						}]
					},
					'representative': {
						value: null,
						matchMode: FilterMatchMode.IN
					},
					'created_date': {
						operator: FilterOperator.AND,
						constraints: [{
							value: null,
							matchMode: FilterMatchMode.DATE_IS
						}]
					},
					'valid_date': {
						operator: FilterOperator.AND,
						constraints: [{
							value: null,
							matchMode: FilterMatchMode.DATE_IS
						}]
					},
					'price': {
						operator: FilterOperator.AND,
						constraints: [{
							value: null,
							matchMode: FilterMatchMode.EQUALS
						}]
					},
					'status': {
						operator: FilterOperator.OR,
						constraints: [{
							value: null,
							matchMode: FilterMatchMode.EQUALS
						}]
					},
					'activity': {
						value: null,
						matchMode: FilterMatchMode.BETWEEN
					},
					'verified': {
						value: null,
						matchMode: FilterMatchMode.EQUALS
					}
				}
			},
			clearFilter1() {
				this.initFilters1();
			},
			expandAll() {
				this.expandedRows = this.products.filter(p => p.id);
			},
			collapseAll() {
				this.expandedRows = null;
			},
			formatDate(value) {
				return value.toLocaleDateString('en-US', {
					day: '2-digit',
					month: '2-digit',
					year: 'numeric',
				});
			},
			formatCurrency(value) {
				console.log('价格', value);
				return value.toLocaleString('en-US', {
					style: 'currency',
					currency: 'USD'
				});
			},
			calculateCustomerTotal(name) {
				let total = 0;
				if (this.customer3) {
					for (let customer of this.customer3) {
						if (customer.representative.name === name) {
							total++;
						}
					}
				}

				return total;
			}
		}
	}
</script>

<style scoped lang="scss">
	@import '../assets/demo/badges.scss';

	.order-badge {
		border-radius: var(--border-radius);
		padding: .25em .5rem;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 12px;
		letter-spacing: .3px;
	}

	.order-badge {
		border-radius: var(--border-radius);
		padding: .25em .5rem;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 12px;
		letter-spacing: .3px;

		&.order-valid {
			background: #C8E6C9;
			color: #256029;
		}

		&.order-expired {
			background: #FFCDD2;
			color: #C63737;
		}

		&.order-verifing {
			background: #FEEDAF;
			color: #8A5340;
		}

		&.order-returned {
			background: #ECCFFF;
			color: #694382;
		}
	}
</style>
